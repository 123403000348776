
























































































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import ListTile from '@/components/ListTile/index.vue'
import TextUtil from '@/utils/text'
import CustomerApi from '@/api/customer'
import { IClueData, IFeiyuClueData, ITagData } from '@/api/types'
import { IContactData } from '@/constant/models'
import EditableSpan from '@/components/EditableSpan/index.vue'
import Constant, { TagType } from '@/constant/constant'
import EditableRemark from '@/components/EditableRemark/index.vue'
import Empty from '@/components/Empty/index.vue'
import Events from '@/constant/events'
import LensSpan from '@/components/LensSpan/index.vue'
import PermissionConst from '@/constant/permissions'
import { UserModule } from '@/store/modules/user'
import { WebCallApi } from '@/proxy'

@Component({
  name: 'CustomerClueDetail',
  components: {
    LensSpan,
    Empty,
    EditableRemark,
    EditableSpan,
    ListTile
  }
})
export default class extends Vue {
  @Prop({
    default: '',
    required: true
  }) private clueId!: string

  @Prop({ default: false }) private visible!: boolean
  @Prop({ default: false }) private allocate!: boolean
  @Prop({ default: false }) private invalid!: boolean
  @Prop({ default: false }) private loseOrder!: boolean
  @Prop({ default: false }) private successOrder!: boolean
  @Prop({ default: false }) private createOrder!: boolean
  @Prop({ default: false }) private follow!: boolean

  private tagTypes = TagType
  private activeName = 'second'
  private clue: IClueData = {
    feiyuClue: {},
    tags: []
  }

  private get showStatusRemark() {
    let show = false
    switch (this.clue.clueStatus) {
      case Constant.ClueStatus.Invalid.value:
        show = true
        break
      case Constant.ClueStatus.OrderFailed.value:
        show = true
        break
      case Constant.ClueStatus.OrderSucceeded.value:
        show = true
        break
    }
    return show
  }

  private get statusLabel() {
    let label = ''
    switch (this.clue.clueStatus) {
      case Constant.ClueStatus.Invalid.value:
        label = '失效'
        break
      case Constant.ClueStatus.OrderFailed.value:
        label = '输单'
        break
      case Constant.ClueStatus.OrderSucceeded.value:
        label = '成单'
        break
    }
    return label
  }

  private get feiyuClue(): IFeiyuClueData {
    return this.clue.feiyuClue ?? {}
  }

  @Watch('visible', { immediate: true })
  private onClueIdChange(visible: boolean) {
    if (visible && TextUtil.isNotEmpty(this.clueId)) {
      this.refreshClueDetail()
    }
  }

  created() {
    this.$EventBus.$on(Events.TAG_SET, (data: Map<string, ITagData[]>) => {
      if (this.clue.id && data.has(this.clue.id)) {
        this.clue.tags = data.get(this.clue.id) ?? []
      }
      if (this.clue.customerId && data.has(this.clue.customerId)) {
        this.clue.customerTags = data.get(this.clue.customerId) ?? []
      }
    })
    this.$EventBus.$on(Events.ADD_CONTACT, (customerId: string, contactList: IContactData[]) => {
      if (this.clue.customerId === customerId && contactList && contactList.length > 0) {
        this.clue.contactWayTitles = []
        this.clue.contactWays = []
        this.clue.contactWayDictIds = []
        contactList.forEach(contact => {
          this.clue.contactWayTitles?.push(contact.methodName)
          this.clue.contactWays?.push(contact.value)
          this.clue.contactWayDictIds?.push(contact.dictId)
        })
      }
    })
  }

  destroyed() {
    this.$EventBus.$off(Events.TAG_SET)
    this.$EventBus.$off(Events.ADD_CONTACT)
  }

  private get showAddContact() {
    return PermissionConst.hasPermission(UserModule.permissions, PermissionConst.Group.DpBackend.Customers.AddContactWay)
  }

  private async refreshClueDetail() {
    const { data } = await CustomerApi.getClueDetail(this.clueId)
    if (data) {
      this.clue = data
    }
  }

  private onAllocateClick() {
    this.$emit('allocateClick')
  }

  private onInvalidClick() {
    this.$emit('invalidClick')
  }

  private onOrderLoseClick() {
    this.$emit('orderLoseClick')
  }

  private onOrderSuccessClick() {
    this.$emit('orderSuccessClick')
  }

  private onOrderCreateClick() {
    this.$emit('orderCreateClick')
  }

  private onClueFollowClick() {
    this.$emit('clueFollowClick')
  }

  private onContactAddClick() {
    this.$emit('contactAddClick')
  }

  private onTagSettingClick(type: TagType) {
    this.$emit('tagSettingClick', type)
  }

  private async onEditRemarkConfirm(editRemark: string) {
    if (!editRemark) {
      return
    }
    if (!this.clue.id) {
      return
    }
    const { status } = await CustomerApi.editClueRemarks(this.clue.id, editRemark)
    if (status === 204) {
      this.clue.remark = editRemark
    }
  }

  private async onCustomerContactUpdate(index: number, contact: string) {
    if (!this.clue.customerId) {
      return
    }
    const tempArr = this.$filterArray(this.clue.contactWays).concat()
    tempArr[index] = contact
    const contactList: IContactData[] = []
    tempArr.forEach((way: string, i: number) => {
      contactList.push({
        value: way,
        dictId: this.$filterArray(this.clue.contactWayDictIds)[i],
        methodName: this.$filterArray(this.clue.contactWayTitles)[i]
      })
    })
    const { status } = await CustomerApi.editCustomerContact(this.clue.customerId, contactList)
    if (status === 204) {
      this.clue.contactWays = tempArr
    }
  }

  private async callPhone(number: string) {
    const { data } = await new WebCallApi().apiAppWebCallCallPost({
      customerId: this.clue.customerId,
      customerMobile: number,
      clueId: this.clueId
    })
    if (!data?.isOk) {
      this.$message.error(data.message)
    }
  }
}
