































































import { Component, Prop, Watch } from 'vue-property-decorator'
import { IDictData, IOrderData } from '@/api/types'
import AppService from '@/services/app'
import CustomerApi from '@/api/customer'
import MarketingApi from '@/api/marketing'
import OrderExpandProductHeader from '@/components/OrderExpandProductHeader/index.vue'
import OrderExpandProductItem from '@/components/OrderExpandProductItem/index.vue'
import ValidateUtil from '@/utils/validate'
import { mixins } from 'vue-class-component'
import ResetMixin from '@/dialogs/mixins/reset-mixin'

@Component({
  name: 'SuccessOrderDialog',
  components: {
    OrderExpandProductItem,
    OrderExpandProductHeader
  }
})
export default class extends mixins(ResetMixin) {
  @Prop({ default: false }) private visible!: boolean
  @Prop({
    default: '',
    required: true
  }) private clueId!: string

  private clueSucceedCauses: IDictData[] = []
  private currentCauseId = ''

  private searchOrderNo = ''
  private order: IOrderData = {
    id: '',
    products: [],
    mobile: '',
    remark: ''
  }

  private remarks = ''

  @Watch('visible', { immediate: true })
  private onVisibleChange(visible: boolean) {
    if (visible) {
      this.clueSucceedCauses = this.$filterArray(AppService.ClueSucceedCauses?.dictItem)
      if (this.clueSucceedCauses.length > 0) {
        this.currentCauseId = this.clueSucceedCauses[0].id
      }
    }
  }

  private get dialogVisible() {
    return this.visible
  }

  private set dialogVisible(value: boolean) {
    if (!value) {
      this.close()
    }
  }

  private async querySearchAsync(queryString: string, cb: Function) {
    let results: any = []
    if (!ValidateUtil.isUUID(queryString)) {
      cb(results)
      return
    }
    const { data } = await MarketingApi.orderSearch(queryString)
    if (data) {
      results = [
        {
          ...data,
          value: data.id
        }
      ]
      cb(results)
    } else {
      cb(results)
    }
  }

  private handleSelectCustomer(item: IOrderData) {
    if (!item) {
      return
    }
    this.order = item
  }

  private async confirmSucceedOrder() {
    if (!this.currentCauseId) {
      this.$message.warning(this.$t('clue.selectSuccessOrderCause').toString())
      return
    }
    if (!this.order.id) {
      this.$message.warning('请输入订单编号并选择订单')
      return
    }
    const { status } = await CustomerApi.clueOrderSucceed(
      this.clueId,
      this.order.id,
      this.currentCauseId,
      this.remarks
    )
    if (status === 204) {
      this.$emit('orderSuccess')
      this.close()
    }
  }

  private close() {
    this.$emit('close')
  }
}
