





































































import { Vue, Component, Prop } from 'vue-property-decorator'
import { ICorpUserData, IProductData, ITagGroupData } from '@/api/types'
import SystemApi from '@/api/system'
import { IFilterData, IMultipleSelectOptionData } from '@/constant/models'
import MarketingApi from '@/api/marketing'
import Constant from '@/constant/constant'
import WecomApi from '@/api/wecom'
import { AppModule } from '@/store/modules/app'

@Component({
  name: 'ClueSearchHeader'
})
export default class extends Vue {
  @Prop({ default: true }) private statusFilter!: boolean
  @Prop({ default: false }) private showCreate!: boolean
  private searchKeyword = ''

  private ClueDateRangeType = Constant.ClueDateRangeType
  private dateRangeType = this.ClueDateRangeType.AllocatedAt.value
  private dateRangeValue: Date[] = []

  private clueFilterValue = []
  private clueFilterOptions: IMultipleSelectOptionData[] = []

  private selectTags = []
  private tagOptions: IMultipleSelectOptionData[] = []

  created() {
    this.refreshClueFilterOptions()
    this.refreshTagOptions()
  }

  private onResetFilter() {
    this.searchKeyword = ''
    this.dateRangeValue = []
    this.clueFilterValue = []
    this.selectTags = []
    this.onSearch()
  }

  private onSearch() {
    const selectTagIds: string[] = []
    this.selectTags.forEach(selectTag => {
      if (this.$filterArray(selectTag).length > 1) {
        selectTagIds.push(selectTag[1])
      }
    })
    const filter: IFilterData = {
      filter: this.searchKeyword,
      dateRangeType: this.dateRangeType,
      tagIds: selectTagIds
    }
    if (this.$filterArray(this.dateRangeValue).length === 2) {
      filter.start = this.dateRangeValue[0]
      this.dateRangeValue[1].setHours(23, 59, 59)
      filter.end = this.dateRangeValue[1]
    }
    const productIds: string[] = []
    const sourceList: string[] = []
    const statusList: string[] = []
    const creatorList: string[] = []
    const followerList: string[] = []
    this.clueFilterValue.forEach((result: Array<string>) => {
      if (result.length === 2) {
        const type = result[0]
        const value = result[1]
        switch (type) {
          case 'product':
            productIds.push(value)
            break
          case 'source':
            sourceList.push(value)
            break
          case 'status':
            statusList.push(value)
            break
          case 'creator':
            creatorList.push(value)
            break
          case 'follower':
            followerList.push(value)
            break
        }
      }
    })
    filter.productIds = productIds
    filter.clueSourceTypes = sourceList
    filter.clueStatusTypes = statusList
    filter.clueCreatorIds = creatorList
    filter.clueCurrentOwnerIds = followerList
    this.$emit('search', filter)
  }

  private onCreate() {
    AppModule.OpenCreateClueDialog({
      customerId: '',
      customerName: '',
      source: Constant.ClueSourceType.BackendNew.value,
      contactWayTitles: [],
      contactWays: [],
      contactWayDictIds: []
    })
  }

  private async refreshClueFilterOptions() {
    this.clueFilterOptions = []
    const productList: IProductData[] = await this.refreshProductList()
    if (productList.length > 0) {
      this.clueFilterOptions.push({
        value: 'product',
        label: '意向商品',
        children: productList.map(product => {
          return {
            value: product.id,
            label: product.name ?? ''
          }
        })
      })
    }
    this.clueFilterOptions.push({
      value: 'source',
      label: '线索来源',
      children: Object.keys(Constant.ClueSourceType).map(key => {
        return {
          value: key,
          label: this.$clueSourceLabel(key)
        }
      })
    })
    if (this.statusFilter) {
      this.clueFilterOptions.push({
        value: 'status',
        label: '线索状态',
        children: Object.keys(Constant.ClueStatus).map(key => {
          return {
            value: key,
            label: this.$clueStatusLabel(key)
          }
        })
      })
    }
    const corpUserList: ICorpUserData[] = await this.refreshCorpUserList()
    if (corpUserList.length > 0) {
      this.clueFilterOptions.push({
        value: 'creator',
        label: '创建人',
        children: corpUserList.map(user => {
          return {
            value: user.abpUserId ?? '',
            label: user.name ?? ''
          }
        })
      })
      this.clueFilterOptions.push({
        value: 'follower',
        label: '归属人',
        children: corpUserList.map(user => {
          return {
            value: user.abpUserId ?? '',
            label: user.name ?? ''
          }
        })
      })
    }
  }

  private async refreshCorpUserList(): Promise<ICorpUserData[]> {
    let corpUserList: ICorpUserData[] = []
    const { data } = await WecomApi.getCorpUserSearch()
    if (data) {
      corpUserList = data
    }
    return corpUserList
  }

  private async refreshProductList(): Promise<IProductData[]> {
    const { data } = await MarketingApi.productList()
    let productList: IProductData[] = []
    if (data) {
      productList = data
    }
    return productList
  }

  private async refreshTagOptions() {
    const { data } = await SystemApi.getAllTagGroupRead()
    if (data) {
      (data as ITagGroupData[]).forEach(group => {
        this.tagOptions.push({
          value: group.id,
          label: group.groupName,
          children: group.tags.map(tag => {
            return {
              value: tag.id,
              label: tag.tagName
            }
          })
        })
      })
    }
  }
}
