





































import { Component, Prop, Watch } from 'vue-property-decorator'
import { IDictData } from '@/api/types'
import AppService from '@/services/app'
import CustomerApi from '@/api/customer'
import { mixins } from 'vue-class-component'
import ResetMixin from '@/dialogs/mixins/reset-mixin'

@Component({
  name: 'InvalidClueDialog'
})
export default class extends mixins(ResetMixin) {
  @Prop({ default: false }) private visible!: boolean
  @Prop({
    default: '',
    required: true
  }) private clueId!: string

  private clueInvalidCauses: IDictData[] = []
  private currentCauseId = ''

  private remarks = ''

  @Watch('visible', { immediate: true })
  private onVisibleChange(visible: boolean) {
    if (visible) {
      this.clueInvalidCauses = this.$filterArray(AppService.ClueInvalidCauses?.dictItem)
      if (this.clueInvalidCauses.length > 0) {
        this.currentCauseId = this.clueInvalidCauses[0].id
      }
    }
  }

  private get dialogVisible() {
    return this.visible
  }

  private set dialogVisible(value: boolean) {
    if (!value) {
      this.close()
    }
  }

  private async confirmInvalid() {
    if (!this.currentCauseId) {
      this.$message.warning(this.$t('clue.selectInvalidClueCause').toString())
      return
    }
    const { status } = await CustomerApi.invalidClue(
      this.clueId,
      this.currentCauseId,
      this.remarks
    )
    if (status === 204) {
      this.$emit('clueInvalid')
      this.close()
    }
  }

  private close() {
    this.$emit('close')
  }
}
